import React from "react";
import { IconProps } from "../icon";

export const ShieldOffIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.809 3.197l2.63-.986c.207-.078.311-.117.418-.133a1 1 0 01.286 0c.107.016.21.055.419.133l5.362 2.01c.748.28 1.123.421 1.398.664a2 2 0 01.547.79c.131.343.131.743.131 1.542V12c0 .72-.115 1.412-.318 2.07m-2.06 3.555c-1.686 1.96-3.834 3.354-4.922 3.989-.223.13-.334.194-.49.228a1.137 1.137 0 01-.42 0c-.156-.034-.267-.098-.488-.227C9.354 20.478 4 16.908 4 12V5.776c0-.467.29-.885.727-1.049M3 3l18 18"
    />
  </svg>
);
