import React from "react";
import { IconProps } from "../icon";

export const AlarmClockOffIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10.5 5.142a7.999 7.999 0 019.358 9.358m-1.722 3.634A8 8 0 116.862 6.869M4 4L2 6m20 0l-3-3M6 19l-2 2m17 0L3 3"
    />
  </svg>
);
