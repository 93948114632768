import React from "react";
import { IconProps } from "../icon";

export const Fingerprint_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6.918 3.386A9.954 9.954 0 0112 2c5.523 0 10 4.477 10 10v.1M3.38 6.925A9.954 9.954 0 002 12c0 4.66 3.187 8.575 7.5 9.685m11.208-4.764a10.02 10.02 0 01-6.208 4.764m-.41-15.311A6 6 0 006.377 14.1m11.247-4.192a6 6 0 01-7.701 7.723M12 10v4"
    />
  </svg>
);
