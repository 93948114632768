import React from "react";
import { IconProps } from "../icon";

export const UserRight_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 9l3-3m0 0l-3-3m3 3h-6m0 15v-1.2c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C13.72 15 12.88 15 11.2 15H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C2 17.28 2 18.12 2 19.8V21M12.5 7.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0z"
    />
  </svg>
);
