import React from "react";
import { IconProps } from "../icon";

export const UsersCheckIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 18l2 2 4-4m-10-1H8c-1.864 0-2.796 0-3.53.305a4 4 0 00-2.166 2.164C2 18.204 2 19.136 2 21M15.5 3.29a4.001 4.001 0 010 7.42M13.5 7a4 4 0 11-8 0 4 4 0 018 0z"
    />
  </svg>
);
