import React from "react";
import { IconProps } from "../icon";

export const Star_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11.29 3.366c.23-.442.344-.663.498-.735a.5.5 0 01.423 0c.154.072.269.293.498.735l1.514 2.913c.099.19.148.286.222.349a.5.5 0 00.23.11c.095.019.2-.002.412-.043l3.22-.633c.489-.096.733-.144.885-.069a.5.5 0 01.264.331c.04.165-.061.393-.264.848l-1.333 2.999c-.088.196-.131.294-.135.392a.5.5 0 00.057.248c.045.086.127.156.291.295l2.503 2.123c.38.322.57.483.605.65a.5.5 0 01-.094.412c-.104.134-.345.197-.827.322l-3.176.827c-.208.054-.312.082-.39.14a.5.5 0 00-.159.199c-.04.089-.043.196-.05.411l-.099 3.28c-.015.498-.023.748-.13.879a.5.5 0 01-.381.183c-.17.003-.37-.147-.768-.445l-2.627-1.968c-.172-.129-.258-.193-.352-.218a.498.498 0 00-.255 0c-.094.025-.18.09-.352.218l-2.627 1.968c-.399.298-.598.448-.768.445a.5.5 0 01-.38-.183c-.108-.131-.116-.38-.131-.878l-.1-3.28c-.006-.216-.01-.323-.049-.412a.5.5 0 00-.158-.2c-.079-.058-.183-.085-.39-.139l-3.177-.827c-.482-.125-.723-.188-.827-.322a.5.5 0 01-.094-.412c.036-.167.226-.328.605-.65l2.503-2.123c.164-.14.246-.209.291-.295a.5.5 0 00.057-.248c-.004-.098-.047-.196-.135-.392L4.808 7.172c-.203-.455-.304-.683-.264-.848a.5.5 0 01.264-.33c.152-.076.396-.028.885.068l3.22.633c.211.041.317.062.413.043a.5.5 0 00.229-.11c.074-.063.124-.159.223-.35l1.513-2.912z"
    />
  </svg>
);
