import React from "react";
import { IconProps } from "../icon";

export const AlarmClockMinusIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 3L2 6m20 0l-3-3M6 19l-2 2m14-2l2 2M9 13h6m-3 8a8 8 0 100-16.001A8 8 0 0012 21z"
    />
  </svg>
);
