import React from "react";
import { IconProps } from "../icon";

export const Key_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 9c0-.512-.195-1.024-.586-1.414A1.994 1.994 0 0015 7m0 8a6 6 0 10-5.946-5.193c.058.434.087.651.068.789a.853.853 0 01-.117.346c-.068.121-.187.24-.426.479l-5.11 5.11c-.173.173-.26.26-.322.36a1 1 0 00-.12.29C3 17.296 3 17.418 3 17.663V19.4c0 .56 0 .84.109 1.054a1 1 0 00.437.437C3.76 21 4.04 21 4.6 21H7v-2h2v-2h2l1.58-1.58c.238-.238.357-.357.478-.425a.852.852 0 01.346-.117c.138-.02.355.01.789.068.264.036.533.054.807.054z"
    />
  </svg>
);
