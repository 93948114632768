import React from "react";
import { IconProps } from "../icon";

export const Hourglass_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18.163 2H5.837A.837.837 0 005 2.837a10.05 10.05 0 002.943 7.106l1.22 1.22c.12.12.181.181.224.24a1 1 0 010 1.195 2.508 2.508 0 01-.224.24l-1.22 1.219A10.05 10.05 0 005 21.163c0 .462.375.837.837.837h12.326a.837.837 0 00.837-.837 10.05 10.05 0 00-2.943-7.106l-1.22-1.22c-.12-.12-.181-.181-.224-.24a1 1 0 010-1.195c.043-.058.103-.118.224-.24l1.22-1.219A10.05 10.05 0 0019 2.837.837.837 0 0018.163 2z"
    />
  </svg>
);
