import React from "react";
import { IconProps } from "../icon";

export const UsersEditIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11 15H8c-1.864 0-2.796 0-3.53.305a4 4 0 00-2.166 2.164C2 18.204 2 19.136 2 21M15.5 3.29A4.001 4.001 0 0118 7m-6 14.5l2.025-.405c.176-.035.265-.053.347-.085a.994.994 0 00.207-.111c.073-.05.136-.114.264-.242L21.5 14a1.414 1.414 0 10-2-2l-6.658 6.657c-.127.128-.19.191-.241.264a1.001 1.001 0 00-.11.207c-.033.082-.05.17-.086.347L12 21.5zM13.5 7a4 4 0 11-8 0 4 4 0 018 0z"
    />
  </svg>
);
