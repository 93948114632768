import React from "react";
import { IconProps } from "../icon";

export const Fingerprint_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.1 21.221a12.16 12.16 0 01-2.207-7.002 6.108 6.108 0 016.106-6.11 6.108 6.108 0 016.106 6.11"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.1 21.221a12.16 12.16 0 01-2.207-7.002 6.108 6.108 0 016.106-6.11 6.108 6.108 0 016.106 6.11"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18.436 20.312c-.11.006-.218.016-.329.016a6.108 6.108 0 01-6.106-6.11"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18.436 20.312c-.11.006-.218.016-.329.016a6.108 6.108 0 01-6.106-6.11"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13.27 22a9.158 9.158 0 01-4.323-7.781 3.054 3.054 0 116.107 0 3.054 3.054 0 106.108 0c0-5.062-4.102-9.164-9.16-9.164-5.059 0-9.16 4.102-9.16 9.164 0 1.128.126 2.226.358 3.286"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13.27 22a9.158 9.158 0 01-4.323-7.781 3.054 3.054 0 116.107 0 3.054 3.054 0 106.108 0c0-5.062-4.102-9.164-9.16-9.164-5.059 0-9.16 4.102-9.16 9.164 0 1.128.126 2.226.358 3.286"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20.526 5.863A11.327 11.327 0 0012 2a11.327 11.327 0 00-8.525 3.863"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20.526 5.863A11.327 11.327 0 0012 2a11.327 11.327 0 00-8.525 3.863"
    />
  </svg>
);
