import React from "react";
import { IconProps } from "../icon";

export const Fingerprint_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 10v4M7.447 3.421A8 8 0 0120 10v1.237M4.416 7.447A7.99 7.99 0 004 10v4a8.004 8.004 0 005.744 7.677m9.915-5.358a8.02 8.02 0 01-4.922 5.2m-.412-15.373A4.5 4.5 0 007.5 10v2.95m9-1.91V14a4.5 4.5 0 01-6.82 3.857"
    />
  </svg>
);
