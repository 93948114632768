import React from "react";
import { IconProps } from "../icon";

export const Star_01FilledIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M11.354 1.776a1.5 1.5 0 011.292 0c.353.168.547.46.645.62.102.166.206.377.308.583l.015.032 2.14 4.335 4.822.705c.227.033.46.067.65.113.182.044.52.139.789.423a1.5 1.5 0 01.398 1.228c-.051.387-.27.663-.391.805-.127.148-.295.312-.46.472l-3.488 3.397.823 4.799c.04.226.08.458.095.652.014.187.029.538-.158.882a1.5 1.5 0 01-1.045.759c-.384.071-.713-.051-.887-.123a7.754 7.754 0 01-.591-.291L12 18.9l-4.31 2.267c-.204.107-.412.216-.592.29-.173.073-.503.195-.887.124a1.5 1.5 0 01-1.045-.76c-.187-.343-.172-.694-.158-.881.016-.194.056-.426.095-.652l.823-4.799-3.463-3.372-.025-.025c-.165-.16-.333-.324-.46-.472-.122-.142-.34-.418-.39-.805a1.5 1.5 0 01.397-1.228c.27-.284.607-.38.79-.423.189-.046.422-.08.649-.113l.034-.005 4.788-.7 2.14-4.335.016-.032c.101-.206.205-.417.307-.582.098-.16.292-.453.646-.621z"
      clipRule="evenodd"
    />
  </svg>
);
