import React from "react";
import { IconProps } from "../icon";

export const FileLock_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14 2H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C6.28 22 7.12 22 8.8 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C20 19.72 20 18.88 20 17.2V8m-6-6l6 6m-6-6v6h6m-6.25 6v-1.75a1.75 1.75 0 10-3.5 0V14m-.15 4h3.8c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437c.109-.214.109-.494.109-1.054v-.8c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C14.74 14 14.46 14 13.9 14h-3.8c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C8.5 14.76 8.5 15.04 8.5 15.6v.8c0 .56 0 .84.109 1.054a1 1 0 00.437.437C9.26 18 9.54 18 10.1 18z"
    />
  </svg>
);
