import React from "react";
import { IconProps } from "../icon";

export const ClockStopwatchIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 9.5v4l2.5 1.5M12 5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zm0 0V2m-2 0h4m6.329 3.592l-1.5-1.5.75.75m-15.908.75l1.5-1.5-.75.75"
    />
  </svg>
);
