import React from "react";
import { IconProps } from "../icon";

export const Dice_6Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16.25 7.75h.005m-.005 4h.005m-.005 4.5h.005M7.75 7.75h.005m-.005 4h.005m-.005 4.5h.005M7.8 21h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C21 18.72 21 17.88 21 16.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C18.72 3 17.88 3 16.2 3H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C3 5.28 3 6.12 3 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C5.28 21 6.12 21 7.8 21zm8.7-13.25a.25.25 0 11-.5 0 .25.25 0 01.5 0zm0 4a.25.25 0 11-.5 0 .25.25 0 01.5 0zm0 4.5a.25.25 0 11-.5 0 .25.25 0 01.5 0zM8 7.75a.25.25 0 11-.5 0 .25.25 0 01.5 0zm0 4a.25.25 0 11-.5 0 .25.25 0 01.5 0zm0 4.5a.25.25 0 11-.5 0 .25.25 0 01.5 0z"
    />
  </svg>
);
